body {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
  width: 100%;
  border: solid;
  overflow: hidden;
  box-sizing: border-box;
}
